<template>
  <!-- TODO: add page here -->
  <div>
    <header class="mx-auto flex max-w-[544px] flex-col gap-[18px] px-3 py-24 text-center">
      <h1 class="text-7xl font-medium text-title-reversed">{{ $t("home.hero.title") }}</h1>
      <p class="text-lg text-subtitle">
        {{ $t("home.hero.description") }}
      </p>
      <div class="mx-auto w-full max-w-[348px]">
        <GlobalSearch
          input-class="p-3 text-eerie-black placeholder:text-eerie-black/60 border border-[#efefef] bg-white"
        />
      </div>
    </header>
    <section class="relative py-8 pt-0">
      <NuxtImg src="/our-approach-decoration.svg" alt="" class="absolute -top-8 w-full" />
      <div class="relative mx-auto max-w-[1064px] rounded-md bg-surface-0">
        <header class="px-10 py-8">
          <h2 class="font-medium text-title">{{ $t("home.ourApproach.title") }}</h2>
        </header>
        <ul
          class="grid-cols grid border-t border-[#303030] text-white md:grid-cols-2 lg:grid-cols-4"
        >
          <li
            v-for="(item, index) in ourApproachData"
            :key="item.title"
            class="grid grid-rows-[auto,_1fr] gap-3 border-r border-[#303030] p-10 last:border-r-0"
          >
            <NuxtImg :src="'/icons/our-approach-' + (index + 1) + '.svg'" alt="" class="size-8" />
            <div class="grid auto-rows-auto gap-8">
              <h3 class="text-xl font-medium">
                {{ $t(`home.ourApproach.items.${index}.title`) }}
              </h3>
              <p>{{ $t(`home.ourApproach.items.${index}.description`) }}</p>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <section class="relative border-t border-stroke bg-white">
      <TabsRoot
        class="mx-auto grid w-full max-w-[1064px] px-3 md:grid-cols-[0.3fr,_0.7fr]"
        default-value="home-page-antibodies"
      >
        <div class="border-stroke py-8 md:border-r md:py-24 md:pr-16">
          <h2
            class="flex items-center gap-2 font-custom-mono-space text-base font-[600] text-title-reversed"
          >
            <NuxtImg src="/icons/our-products.svg" alt="" class="size-6" />
            {{ $t("home.products.title") }}
          </h2>
          <TabsList class="mt-6 flex flex-wrap items-start gap-2 md:flex-col">
            <TabsTrigger
              value="home-page-antibodies"
              class="rounded border border-stroke px-2 py-1 font-custom-mono-space font-[600] text-title-reversed data-[state=active]:border-surface-0 data-[state=active]:bg-surface-0 data-[state=active]:text-white"
            >
              {{ $t("home.products.categories.antibodies") }}
            </TabsTrigger>
            <TabsTrigger
              value="home-page-proteins"
              class="rounded border border-stroke px-2 py-1 font-custom-mono-space font-[600] text-title-reversed data-[state=active]:border-surface-0 data-[state=active]:bg-surface-0 data-[state=active]:text-white"
              >{{ $t("home.products.categories.proteins") }}
            </TabsTrigger>
            <TabsTrigger
              value="home-page-enzymes"
              class="rounded border border-stroke px-2 py-1 font-custom-mono-space font-[600] text-title-reversed data-[state=active]:border-surface-0 data-[state=active]:bg-surface-0 data-[state=active]:text-white"
            >
              {{ $t("home.products.categories.enzymes") }}
            </TabsTrigger>
          </TabsList>
        </div>
        <div class="py-8 md:py-14 md:pl-16">
          <TabsContent
            v-for="(products, collectionSlug) in productsByCollection"
            :key="collectionSlug"
            :value="collectionSlug"
            class="outline-none"
          >
            <ul>
              <li v-for="product in products" :key="product.slug" class="border-b border-b-stroke">
                <NuxtLinkLocale
                  :to="`/categories/${product.categorySlug}/${product.slug}`"
                  class="group flex items-center justify-between py-8"
                >
                  <span class="flex items-center gap-4 text-xl font-medium text-title-reversed">
                    <NuxtImg
                      v-if="product.image"
                      :src="product.image"
                      alt=""
                      class="size-20 rounded object-contain"
                    />
                    <span class="max-w-xs">
                      {{ product.title }}
                    </span>
                  </span>
                  <span
                    class="rounded border border-stroke p-2.5 transition-colors group-hover:border-icon-surface-focus group-hover:bg-icon-surface-focus"
                  >
                    <Icon
                      icon="akar-icons:arrow-up-right"
                      class="size-4 text-eerie-black transition-colors group-hover:text-stroke"
                    />
                  </span>
                </NuxtLinkLocale>
              </li>
            </ul>
          </TabsContent>
        </div>
      </TabsRoot>
    </section>
    <section class="relative border-t border-stroke bg-white">
      <div class="mx-auto grid w-full max-w-[1064px] px-3 md:grid-cols-[0.3fr,_0.7fr]">
        <div class="border-stroke py-8 md:border-r md:py-24 md:pr-16">
          <h2
            class="flex items-center gap-2 font-custom-mono-space text-base font-[600] text-title-reversed"
          >
            <NuxtImg src="/icons/testimonials.svg" alt="" class="size-6" />
            {{ $t("home.testimonials.title") }}
          </h2>
        </div>
        <div class="py-8 md:py-24 md:pl-16">
          <div
            class="relative"
            role="region"
            aria-roledescription="carousel"
            aria-label="Testimonials"
          >
            <Transition name="fade" mode="out-in">
              <div
                :key="currentSlide"
                class="flex flex-col gap-12"
                role="group"
                aria-roledescription="slide"
                :aria-label="`${currentSlide + 1} of ${testimonialData.length}`"
              >
                <blockquote>
                  <p class="text-3xl font-medium text-title-reversed">
                    "{{ testimonialData[currentSlide].text }}"
                  </p>
                </blockquote>
                <div class="h-px bg-stroke" />
                <div class="flex items-center gap-4">
                  <NuxtImg
                    :src="testimonialData[currentSlide].image"
                    :alt="`${testimonialData[currentSlide].name}'s profile picture`"
                    class="size-10 rounded-full"
                  />
                  <div class="leading-5">
                    <p class="font-medium text-title-reversed">
                      {{ testimonialData[currentSlide].name }}
                    </p>
                    <p class="mt-2 font-custom-mono-space font-[600] text-subtitle">
                      {{ testimonialData[currentSlide].position }}
                    </p>
                  </div>
                </div>
              </div>
            </Transition>
            <div
              class="absolute bottom-[5px] right-0 flex items-center gap-2 md:gap-4"
              role="group"
              aria-label="Carousel controls"
            >
              <button
                class="group rounded border border-stroke p-2.5 transition-colors hover:border-icon-surface-focus hover:bg-icon-surface-focus"
                :aria-label="$t('home.testimonials.navigation.previous')"
                @click="previousSlide"
                @keydown.left="previousSlide"
                @keydown.right="nextSlide"
              >
                <Icon
                  icon="akar-icons:arrow-left"
                  class="size-4 text-eerie-black transition-colors group-hover:text-stroke"
                  aria-hidden="true"
                />
              </button>
              <div class="flex items-center gap-1">
                <div
                  v-for="(_, index) in testimonialData"
                  :key="index"
                  class="h-1 rounded-full transition-all duration-300"
                  :class="[
                    index === currentSlide ? 'w-4 bg-title-reversed md:w-6' : 'w-1 bg-stroke',
                  ]"
                />
              </div>
              <button
                class="group rounded border border-stroke p-2.5 transition-colors hover:border-icon-surface-focus hover:bg-icon-surface-focus"
                :aria-label="$t('home.testimonials.navigation.next')"
                @click="nextSlide"
                @keydown.left="previousSlide"
                @keydown.right="nextSlide"
              >
                <Icon
                  icon="akar-icons:arrow-right"
                  class="size-4 text-eerie-black transition-colors group-hover:text-stroke"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="relative border-b border-[#303030] bg-surface-0">
      <div class="mx-auto grid w-full max-w-[1064px] px-3 md:grid-cols-[0.3fr,_0.7fr]">
        <NuxtImg
          src="/design-on-the-fly-decoration.svg"
          alt=""
          class="absolute right-0 top-0 h-full"
        />
        <div class="border-[#303030] py-8 md:border-r md:py-24 md:pr-16">
          <h2
            class="flex items-center gap-2 font-custom-mono-space text-base font-[600] text-title"
          >
            <NuxtImg src="/icons/design-on-the-fly.svg" alt="" class="size-6" />
            {{ $t("home.designOnTheFly.title") }}
          </h2>
        </div>
        <div class="relative z-10 max-w-[584px] py-8 text-title md:py-24 md:pl-16">
          <h2 class="text-6xl font-medium">{{ $t("home.designOnTheFly.heading") }}</h2>
          <p class="mt-3 text-lg">
            {{ $t("home.designOnTheFly.description") }}
          </p>
          <a
            href="#"
            class="mt-8 flex w-fit items-center justify-center gap-3 rounded border border-stroke bg-white px-[19px] py-[15px] text-center text-sm font-medium text-title-reversed underline decoration-transparent transition-all duration-300 hover:border-eerie-black hover:decoration-title-reversed"
          >
            {{ $t("home.designOnTheFly.learnMore") }} <span class="h-4 w-px bg-[#efefef]" />
            <span class="rounded bg-icon-surface-focus p-0.5">
              <Icon icon="akar-icons:arrow-up-right" class="size-3 text-stroke" />
            </span>
          </a>
        </div>
      </div>
    </section>
  </div>
</template>
<script setup lang="ts">
import { CollectionsDocument } from "@graphql";
import { Icon } from "@iconify/vue";
import { useQuery } from "@vue/apollo-composable";
import { TabsContent, TabsList, TabsRoot, TabsTrigger } from "radix-vue";
import { computed, onMounted, onUnmounted, ref } from "vue";

import { useChannel } from "~/src/channel/composables";

const { channel } = useChannel();

const { result } = useQuery(CollectionsDocument, () => ({
  channel: channel.value,
  first: 3,
}));

const productsByCollection = computed(() => {
  if (!result.value?.collections?.edges) return {};

  return result.value.collections.edges.reduce(
    (acc, edge) => {
      const collectionSlug = edge.node.slug;

      acc[collectionSlug] =
        edge.node.products?.edges.map((productEdge) => ({
          title: productEdge.node.name,
          image: productEdge.node.media?.[0]?.url,
          slug: productEdge.node.slug,
          collection: collectionSlug,
          categorySlug: productEdge.node.category?.slug || "",
        })) || [];
      return acc;
    },
    {} as Record<
      string,
      Array<{
        title: string;
        image?: string;
        slug: string;
        collection: string;
        categorySlug: string;
      }>
    >
  );
});

const currentSlide = ref(0);

const nextSlide = () => {
  currentSlide.value = (currentSlide.value + 1) % testimonialData.length;
};

const previousSlide = () => {
  currentSlide.value = (currentSlide.value - 1 + testimonialData.length) % testimonialData.length;
};

// Handle keyboard navigation
const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "ArrowLeft") {
    previousSlide();
  } else if (event.key === "ArrowRight") {
    nextSlide();
  }
};

onMounted(() => {
  window.addEventListener("keydown", handleKeydown);
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleKeydown);
});

const ourApproachData = [
  {
    title: "AI design",
    description:
      "AI algorithms enable both faster standard antibody design and customized solutions for novel targets.",
  },
  {
    title: "Fast production",
    description:
      "Our design and production pipelines allow us to create novel antibodies within weeks, not years.",
  },
  {
    title: "Animal free design",
    description:
      "By using computational techniques, we ensure ethical product design, eliminating experiments on animals.",
  },
  {
    title: "Guaranteed quality",
    description:
      "Our products undergo rigorous testing using procedures like SDS-PAGE, Western Blot or Mass spectrometry.",
  },
];

const testimonialData = [
  {
    image: "/dummy-testimonial-image.png",
    name: "John Doe1",
    position: "CEO at McDonald's",
    text: "Whether you have a team of 2 or 200, this solution is great!",
  },
  {
    image: "/dummy-testimonial-image.png",
    name: "John Doe2",
    position: "CEO at McDonald's",
    text: "Whether you have a team of 2 or 200, this solution is great!",
  },
  {
    image: "/dummy-testimonial-image.png",
    name: "John Doe3",
    position: "CEO at McDonald's",
    text: "Whether you have a team of 2 or 200, this solution is great!",
  },
];

useSeoMeta({
  // TODO: locallize this
  description: "Main page of Genotic store",
});
</script>
